<template>
  <right-content>
    <edit-info :editInfoList="editInfoList" @submitfn="submitForm"></edit-info>
  </right-content>
</template>

<script>
import { isPhoneNum,isNumber} from "@/utils/validate";
import RightContent from "@/views/commonViews/RightContent";
import { editInformation } from "@/api";
import EditInfo from "@/views/BaseInfo/editInfo";
export default {
  data() {
    return {
      editInfoList: [
        { name: "党员质量监督岗名称", field: "name" },
        { name: "设立时间", field: "found_time" },
        { name: "监督岗总人数", field: "sentries_number",disable:true },
        { name: "岗长", field: "people_name" },
        { name: "党员人数(含农民工党员)", field: "party_number",disable:true},
        { name: "入党积极分子", field: "party_positive_number",disable:true},
        { name: "农民工人数", field: "peasant_number",disable:true },
        { name: "岗长联系电话", field: "people_number"},
      ],
    };
  },
  methods: {
    async submitForm(form) {
      try {
        if (!isPhoneNum(form.people_number)) {
          throw new Error("手机格式错误");
        }
        // if (!isNumber(form.sentries_number)) {
        //   throw new Error("监督岗总人数必须为正整数");
        // }
        // if (!isNumber(form.party_number)) {
        //   throw new Error("党员人数必须为正整数");
        // }
        //  if (!isNumber(form.party_positive_number)) {
        //   throw new Error("入党分子必须为正整数");
        // }
        //  if (!isNumber(form.peasant_number)) {
        //   throw new Error("农民工人数必须为正整数");
        // }
        const requestData = {
          type: 4,
          sentry_id: form.id,
          value: form,
        };
        //删除属性id
        delete requestData.value.id;
        const res = await editInformation(requestData);
        if (res.status == 200) {
          this.$success("修改成功");
          this.$router.back();
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$error(e.message);
        }
      }
    },
  },
  components: {
    EditInfo,
    RightContent,
  },
};
</script>
<style lang="scss">
</style>
